$primary: #060C14;
$secondary: white;
$background: #0F2137;
$primaryLight: #1E334F;
$hover: #5F8CC7;
$error: #F18B7E;

$font: 'YS Text', Roboto, Helvetica, Arial, sans-serif;
$titleFont: 'Garamond', sans-serif;
$fontMono: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
$baseFontSize: 20px;
$baseFontColor: white;

$category1: #83FA9D;
$category2: #FAD883;
$category3: #B783FA;
$category4: #83DDFA;
$category5: #FAA083;

$mainFontSize: 1.5em;
$titleFontSize: 2.5em;
$headerElementsSize: 4.5rem;
$headerHeight: 9rem;

$maxPageWidth: 1320px;
$defaultPadding: 2.4rem;

$defaultBorderRadius: 3rem;
$defaultBorder: 2px solid $primaryLight;
$hoverBorder: 2px solid white;
$overlayBackground: rgba(0, 0, 0, 0.8);
