@use "variables" as *;
@use "mixins";

.form {
  @include mixins.flex(column);
  gap: 1.5rem;
  border-radius: 3rem;

  &__label {
    flex-grow: 1;
  }

  &__input {
    background: transparent;
    color: white;
    border: $defaultBorder;
    border-radius: 1.5rem;
    padding: 1rem;
    font-size: 1.4rem;
    width: 100%;
    box-sizing: border-box;
    outline-color: $secondary;

    &:focus {
      outline-style: solid;
    }

    &:invalid {
      border-color: $error;
      outline-color: $error;
    }

    &::placeholder {
      color: lighten($primaryLight, 23%);
    }
  }
}