/* Generated by Glyphter (http://www.glyphter.com) on  Wed Jun 21 2023*/
@font-face {
    font-family: 'Glyphter';
    src: url('./Glyphter.eot');
    src: url('./Glyphter.eot?#iefix') format('embedded-opentype'),
         url('./Glyphter.woff') format('woff'),
         url('./Glyphter.ttf') format('truetype'),
         url('./Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}

.icon-back:before{content:'\0041';}
.icon-basket:before{content:'\0042';}
.icon-basket-2:before{content:'\0043';}
.icon-chevron:before{content:'\0044';}
.icon-close:before{content:'\0045';}
.icon-square-check-regular:before{content:'\0046';}
.icon-square-regular:before{content:'\0047';}
.icon-star:before{content:'\0048';}
.icon-trash:before{content:'\0049';}