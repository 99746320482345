@use "variables" as *;
@use "mixins";

.gallery {
  @include mixins.container;
  @include mixins.grid(600px, 24px);

  &__item {
    border: none;
    border-radius: $defaultBorderRadius;
    @include mixins.selectable($secondary, 2px, $primaryLight);

    &::after {
      border-radius: 0.8rem;
    }
  }
}