@use "variables" as *;
@use "mixins";

.card {
  @include mixins.flex(column);
  border: 0;
  border-radius: $defaultBorderRadius;
  background-color: $primary;
  color: white;
  padding: $defaultPadding;

  &_full {
    flex-direction: row;
    background-color: transparent;

    .card__title {
      flex-grow: 0;
    }
  }

  &_compact {
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    border-radius: 0;

    .card__title {
      flex-grow: 1;
    }
  }

  &__column {
    @include mixins.flex(column);
    align-items: flex-start;
  }

  &__row {
    @include mixins.flex(row);
    align-items: center;
    gap: $defaultPadding;
  }

  &__image {
    height: 390px;
    aspect-ratio: 1;
    margin: 3.75rem;
  }
  &__title {
    font-size: $titleFontSize;
    font-weight: 500;
    margin: 0;
    flex-grow: 1;
  }
  &__text {
    text-align: left;
    flex-grow: 1;
  }
  &__category {
    padding: 0.5rem 1.25rem;
    border-radius: 2.5rem;
    margin-bottom: 12px;
    color: $primary;

    &_soft {
      background-color: $category1;
    }
    &_hard {
      background-color: $category5;
    }
    &_other {
      background-color: $category2;
    }
    &_additional {
      background-color: $category3;
    }
    &_button {
      background-color: $category4;
    }
  }
  &__action {

  }
  &__price {

  }
}