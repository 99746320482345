@use "variables" as *;
@use "mixins";

.header {
  height: $headerHeight;
  background-color: $primary;

  &__container {
    @include mixins.container(45px,45px);
    @include mixins.flex(row);
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    height: $headerElementsSize;
  }

  &__logo {
    &-image {
      display: block;
      height: 100%;
    }
  }

  &__basket {
    //@include mixins.icon($headerElementsSize, 'basket-2');
    @include mixins.link($primary, $hover);
    background-image: url(../images/shopping_cart.svg);
    height: 58px;
    width: 58px;
    border: none;
    position: relative;
    cursor: pointer;

    &-counter {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: -0.2rem;
      width: 0.8rem;
      height: 0.8rem;
      font-size: 0.5rem;
      background-color: $category1;
      color: $background;
      border-radius: 100%;
      font-weight: 600;
    }
  }
}